var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer", attrs: { id: "footer" } }, [
    _c(
      "p",
      { staticClass: "text-right" },
      [
        _c(
          "router-link",
          { staticClass: "m-2", attrs: { to: "/pages/cookies" } },
          [_vm._v("Cookies")]
        ),
        _vm._v(" "),
        _c(
          "router-link",
          { staticClass: "m-2", attrs: { to: "/pages/contact" } },
          [_vm._v("Kontakt")]
        ),
        _c(
          "router-link",
          { staticClass: "m-2", attrs: { to: "/pages/impressum" } },
          [_vm._v("Impressum")]
        ),
        _c(
          "router-link",
          { staticClass: "m-2", attrs: { to: "/pages/dataprivacy" } },
          [_vm._v("Datenschutz")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }