import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const impressum = () => import('@/pages/impressum/impressum.vue');
// prettier-ignore
const dataprivacy = () => import('@/pages/dataprivacy/dataprivacy.vue');
// prettier-ignore
const cookies = () => import('@/pages/cookies/cookies.vue');
// prettier-ignore
const contact = () => import('@/pages/contact/contact.vue');
// prettier-ignore
const our_vision = () => import('@/pages/our-vision/our-vision.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/pages/impressum',
    name: 'impressum',
    component: impressum,
  },
  {
    path: '/pages/dataprivacy',
    name: 'dataprivacy',
    component: dataprivacy,
  },
  {
    path: '/pages/cookies',
    name: 'cookies',
    component: cookies,
  },
  {
    path: '/pages/contact',
    name: 'contact',
    component: contact,
  },
  {
    path: '/pages/our-vision',
    name: 'our_vision',
    component: our_vision,
  },
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
];
