var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { attrs: { id: "app-header" } }, [_c("jhi-navbar")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container-fluid" },
      [
        _c("div", { staticClass: "card jh-card" }, [_c("router-view")], 1),
        _vm._v(" "),
        _c(
          "b-modal",
          { attrs: { id: "login-page", "hide-footer": "", lazy: "" } },
          [
            _c(
              "span",
              {
                attrs: {
                  slot: "modal-title",
                  "data-cy": "loginTitle",
                  id: "login-title",
                },
                domProps: { textContent: _vm._s(_vm.$t("login.title")) },
                slot: "modal-title",
              },
              [_vm._v("Sign in")]
            ),
            _vm._v(" "),
            _c("login-form"),
          ],
          1
        ),
        _vm._v(" "),
        _c("jhi-footer"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }